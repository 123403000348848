.Button {
  align-self: center;
  padding: 12px;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.Button:hover, .Button.Active {
  background-color: #000;
  color: #fff;
}