.NavMenu {
  display: flex;
  flex-direction: row;
}

.NavMenu > * {
  margin-right: 16px;
}

.NavMenu > *:last-child {
  margin-right: auto;
}

.Link {
  display: flex;
  align-items: center;
  
  padding: 8px;
  border: 1px solid #fff;
  cursor: pointer;

  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.Link:hover, .LinkActive {
  background-color: #fff;
  color: #000;
}