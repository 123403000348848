.AppContainer {
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  background-color: #000;
  color: #fff;
}

.Logo {
  font-size: 18px;
  font-weight: 600;
}

.Page {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 656px;
  margin: auto;

  padding: 16px 64px;
}

.Page > h1, h2, h3 {
  margin: 16px 0px;
  text-align: center;
}

.Page > p {
  margin: 0px;

  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

span.bold {
  font-weight: 600;
}

.Page > .button {
  align-self: center;
  padding: 12px;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.Page > .button:hover {
  background-color: #000;
  color: #fff;
}

form {
  display: flex;
  flex-direction: column;

  width: 100%;
}

form > * {
  margin-bottom: 32px;
}

form > *:last-child {
  margin-bottom: auto;
}

form > button {
  align-self: center;
  padding: 12px;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

form > button:hover {
  background-color: #000;
  color: #fff;
}

.TextField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.TextField > label {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  margin-bottom: 4px;
}

.TextField > input {
  font-size: 16px;
  padding: 8px 0px;

  border: none;
  border-bottom: 1px solid #000;
}

.TextField > div {
  margin-top: 4px;
  font-size: 16px;
}